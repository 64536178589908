<template>
    <div class="page">
        <div class="my-top">
            <div class="info-box" style="">
                <div class="head-portrait">
                    <div class="box">
                        <div class="treasure-font">
                            -元宝-
                        </div>
                        <div class="treasure-num">{{ userInfo.treasureNum }}</div>
                    </div>
<!--                    <van-button plain hairline round type="info" size="small" class="treasure-btn">元宝使用说明</van-button>-->
                  <div class="treasure-btns">
                    <van-button plain hairline round type="info" size="small" class="treasure-btn" @click="showQrcode=true">充值</van-button>
                    <van-button plain hairline round type="info" size="small" class="treasure-btn" @click="showTx=true">提现</van-button>
                  </div>
                </div>
<!--    todo 充值入口 弹客服二维码
-->
            </div>
        </div>

        <div class="list-box">
            <div class="my-list-cell">
                <div class="historical-records">
                    历史记录
                </div>
              <div class="resume-cell" v-for="i in list">
                <div>
                  <van-icon name="peer-pay"/>
                  <span>{{ i.type }}</span>
                </div>
                <div class="eidt-font">
                       <span>
                           {{ i.createdDate }}
                       </span>
                  <span class="span-right">
                          <van-button plain hairline round type="info" size="small" class="span-btn">{{ i.num }}
                        </van-button>
                        </span>
                </div>
              </div>
            </div>
        </div>

      <van-dialog v-model="showQrcode" title="请联系客服进行充值" style="text-align: center">
        <div>客服微信号：602581887</div>
        <div>客服微信二维码</div>
        <img width="280px" src="@/assets/images/kf.jpg" />
      </van-dialog>
      <van-dialog v-model="showTx" title="提现" style="text-align: center" show-cancel-button @confirm="withdrawalSave">
        <div>元宝提现现金比例 10:1</div>
        <div>当前最多可提现：{{userInfo.treasureNum/10}}元</div>
        <van-field v-model="num" label="提现金额" type="number" placeholder="请输入提现金额"/>
      </van-dialog>

    </div>
</template>

<script>
import {getTreasure, getUserInfo} from "../../api/user";
import {save} from "../../api/withdrawal";
import * as demandApi from "../../api/demand";
import {Dialog} from "vant";
    export default {
        data() {
            return {
              showQrcode:false,
              showTx:false,
              list:[],
              userInfo:{},
              params: {
                page: 0,
                size: 40,
              },
              num: undefined,
            }
        },
        mounted() {
          this.loadData()
          this.loadUserInfo()
        },
        methods: {
          async withdrawalSave(){//申请提现
            if (!this.userInfo.payImg){
              Dialog.confirm({
                title: "提醒",message: "请先上传收款二维码",
              }).then(() => {this.$router.push(`my-user-authentication`)})
              return
            }
            if (this.num>(this.userInfo.treasureNum/10)){
              Dialog.alert({title: "提醒",message: "申请金额最大金额为："+this.userInfo.treasureNum/10+'元',})
              return
            }
            this.userInfo.treasureNum=this.userInfo.treasureNum-this.num
            let r = await save({num:this.num});
            Dialog.confirm({
              title: "申请成功",
              message: "申请成功，请等待客服审核发放",
            })
              .then(() => {
              })
          },
            goMyTreasure() {
                this.$router.push(`my-treasure`)
            },
          async loadData() {
            let r = await getTreasure();
            this.list = r.data.content
          },
          async loadUserInfo() {
            let r = await getUserInfo();
            this.userInfo = r.data
          },
        },
        computed: {}
    }

</script>

<style lang="less" scoped>
    .my-top {
        height: 280px;
        width: 100%;
        background: linear-gradient(to right, #0E91FC, #08ADFD);

        .info-box {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: space-around;

            .head-portrait {
                margin-top: 15%;
                width: 120px;
                height: 120px;
                box-sizing: border-box;
                padding: 5px;
                border-radius: 50%;
                background-image: linear-gradient(#FFFFFF, #08ADFD);

                .box {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: #FFFFFF;
                    text-align: center;

                    .treasure-font {
                        padding-top: 20px;
                    }

                    .treasure-num {
                        margin-top: 15px;
                        font-size: 20px;
                        font-weight: 600;
                    }
                }
              .treasure-btns{
                //position: absolute;
                //left: 45%;
                margin-top: 10px;
                width: 100%;
                text-align: center;
                .treasure-btn {
                  width: 50px;
                  //position: absolute;
                  //left: 50%;
                  margin-left: 5px;
                  //transform: translate(-50%, -20px);
                  background: linear-gradient(to right, #F7D48D, #FDA65D);
                  color: #A04306;
                  border: none;
                  font-size: small;
                }
              }

            }
        }
    }

    .list-box {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        height: calc(100vh - 200px);
        margin-top: -30px;
        background: #FFFFFF;

        .historical-records {
            margin-top: 20px;
            margin-left: 15px;
            font-size: 20px;
            color: #3C3C3C;
            font-weight: 600;
        }

        .my-list-cell {
            margin-top: 20px;
            position: absolute;
            width: 100%;
          background: #fff;
            .resume-cell {
                max-width: 700px;
                padding: 15px;
                margin-top: 10px;
                margin-left: 5px;
                border-bottom: 1px solid #F7F7F7;
                color: #212121;
                font-size: 16px;

                span {
                    margin-left: 10px;
                }

                .eidt-font {
                    color: #C3C3C3;
                    font-size: xx-small;
                    margin-left: 15px;
                    margin-top: 10px;

                    .span-right {
                        float: right;
                        margin-right: 10px;

                        .span-btn {
                            height: 20px;
                            width: 50px;
                            background: linear-gradient(to right, #F7D48D, #FDA65D);
                            color: #FFFFFF;
                            border: none;
                        }
                    }
                }
            }
        }
    }

</style>
