import axios from '../http2'

/**
 * save
 * @param {code} data 
 * @returns 
 */
export function save(data) {
    return axios.post('/Withdrawal', data)
}
